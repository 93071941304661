<template lang="html">

  <section class="colaborations">
    <bannerHead dataTitle="Colabora"></bannerHead>
    <breacrumbs dataParent="Responsabilidad Social" dataParentLink="/colabora/voluntariado-corporativo"
      dataTitle="Colabora"></breacrumbs>
    <bannerRsc class="colabora"  titleBanner="colabora"></bannerRsc>
    <section class="container section-text-img">
      <div class="grid-2 aic jcsb">
        <div class="grid">
          <p class="title-blue">
            {{$t('projects.'+slug+'.title')}}
          </p>
          <div v-html="$t('projects.'+slug+'.block1')"></div>
          
        </div>
        
        <div class="d-flex grid jcfe">
          <div class="container-img ">
            <img :src="$t('projects.'+slug+'.imgBlock1')" :alt="slug">
          </div>
        </div>
        <div v-show="slug=='nomina-solidaria'"  v-html="$t('projects.nomina-solidaria.block3')"></div>

      </div>
     
      <div class="grid-2 aic jcsb" v-if="$t('projects.'+slug+'.block2') != ''">
        <div class="grid">
          <div class="container-img">
            <img :src="$t('projects.'+slug+'.imgBlock2')" :alt="slug">
          </div>
        </div>
        <div class="grid">
          <div v-html="$t('projects.'+slug+'.block2')"></div>
        </div>

      </div>
    </section>
    <section class="colaborated">
      <div class="container">
        <p class="title-line -blue">¿Cómo colaborar?</p>
        <div class="grid-3 ">
          <div class="grid box-blue -dark" v-if="$t('projects.'+slug+'.cooperate_step1') != ''">
            <p class="number">
              1
            </p>
            <p>
              <strong>{{$t('projects.'+slug+'.cooperate_step1')}}</strong>
            </p>
          </div>
          <div class="grid box-blue -medium" v-if="$t('projects.'+slug+'.cooperate_step2') != ''">
            <p class="number">
              2
            </p>
            <p>
              <strong>{{$t('projects.'+slug+'.cooperate_step2')}} </strong>
            </p>
          </div>
          <div class="grid box-blue -light" v-if="$t('projects.'+slug+'.cooperate_step3') != ''">
            <p class="number">
              3
            </p>
            <p>
              <strong>{{$t('projects.'+slug+'.cooperate_step3')}}</strong>
            </p>
          </div>
        </div>
        <!-- <div class="row-center" v-html="$t('link_cooperate.'+slug)"></div> -->
        <div class="row-center">
          <a v-if="slug == 'nomina-solidaria'" href='https://ufv.jotform.com/220544282753859' class='btn-blue-border' title="Job Form"
            target='_blank'>Adhesión Nómina Solidaria</a>
          <template v-else>
            <a href='mailto:voluntariado@ufv.es' class='btn-blue-border' target='_blank' title="Contacta con el departamento social de Acción social">Contacta con el departamento de Acción
              Social
              voluntariado@ufv.es</a>
          </template>
        </div>
      </div>
    </section>
  </section>

</template>

<script lang="js">
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  import bannerRsc from '@/components/banner-rsc.vue'

  export default {
    name: 'colaborations',
    props: ['slug'],
    components: {
      bannerHead,
      breacrumbs,
      bannerRsc
    },
    mounted() {

      
    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    },

  }


</script>

<style scoped lang="scss">
  .colaborations {}
</style>